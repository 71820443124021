export default {
  '': '',
  ' ': ' ',
  'Account and data on Orange Admin App': 'Account and data on Orange Admin App',
  'Add': 'Add',
  'Add Shortcut': 'Add Shortcut',
  'Add vip card successfully': 'Add vip card successfully',
  'Address': 'Address',
  'Agree to': 'Agree to',
  'All': 'All',
  'All Coupons': 'All Coupons',
  'All Vip Cards': 'All Vip Cards',
  'Amount': 'Amount',
  'Apply': 'Apply',
  'An exception happened': 'An exception happened',
  'Are you sure to close your account permanently? This will result in the loss of all your data on Orange Wallet.': 'Are you sure to close your account permanently? This will result in the loss of all your data on Orange Wallet.',
  'Are you sure to delete this card?': 'Are you sure to delete this card?',
  'Are you sure to disable this card?': 'Are you sure to disable this card?',
  'Available': 'Available',
  'Available Points': 'Available Points',
  'Back': 'Back',
  'Balance': 'Balance',
  'Balance History': 'Balance History',
  'Bonus': 'Bonus',
  'By File': 'By File',
  'By Parameters': 'By Parameters',
  'Cancel': 'Cancel',
  'Cancel Redeem ': 'Cancel Redeem ',
  'Cards': 'Cards',
  'Card Details': 'Card Details',
  'Card Holder': 'Card Holder',
  'Card Image': 'Image',
  'Card Info': 'Card Info',
  'Card Name': 'Name',
  'Card Number': 'Card Number',
  'Card number is duplicated at line ': 'Card number is duplicated at line ',
  'Card Number Length': 'Card Number Length',
  'Card Number Length is too small': 'Card Number Length is too small',
  'Card Number Prefix': 'Card Number Prefix',
  "Card Number Prefix can't be longer than Card Number Length": "Card Number Prefix can't be longer than Card Number Length",
  'Card Number Start From': 'Card Number Start From',
  'Card Quantity': 'Card Quantity',
  'Card Quantity is too small': 'Card Quantity is too small',
  'Card Search part1': 'Found',
  'Card Search part2': 'Results',
  'Card Type': 'Type',
  'Cell Number': 'Cell Number',
  'Check It Out': 'Check It Out',
  'Chinese': 'Chinese',
  'Claim': 'Claim',
  'Claim Coupon': 'Claim',
  'Claim Now': 'Claim Now',
  'Close': 'Close',
  'Close ': 'Close ',
  'Close Account': 'Close Account',
  'Copy Link': 'Copy Link',
  'Confirm': 'Confirm',
  'Confirm to close account': 'Confirm to close account',
  'Congratulations!': 'Congratulations!',
  'Contact merchant to restore': 'Contact merchant to restore',
  'Contact Name': 'Contact Name',
  'Coupon': 'Coupon',
  'Coupons': 'Coupons',
  'Coupon and other benefits': 'Coupon and other benefits',
  'Coupon can only be applied by members': 'Coupon can only be applied by members',
  'Coupon Center': 'Coupon Center',
  'Coupon Detail': 'Coupon Detail',
  'Coupons available': 'Coupons available',
  'Current Points': 'Current Points',
  'Custom': 'Custom',
  'Custom Reload': 'Custom Reload',
  'Date of Birth': 'Date of Birth',
  'Days': 'Days',
  'Delete': 'Delete',
  'Description': 'Description',
  'Detail': 'Detail',
  'Disable': 'Disable',
  'Disabled': 'Disabled',
  'Disable successfully': 'Disable successfully',
  'Description': 'Description',
  'Do you agree to applied the free VIP member card': 'Do you agree to applied the free VIP member card',
  'Email': 'Email',
  'English': 'English',
  'Enter address': 'Enter address',
  'Enter cell number': 'Enter cell number',
  'Enter Cellphone Number': 'Enter Cellphone Number',
  'Enter Code': 'Enter Code',
  'Enter email address': 'Enter email address',
  'Enter name': 'Enter name',
  'Enter password': 'Enter password',
  'Event Details': 'Event Details',
  'Exclusive': 'Exclusive',
  'Expired': 'Expired',
  'Expired Date:': 'Expired Date:',
  'Expiry Date': 'Expiry Date',
  'Expired QR Code': 'Expired QR Code',
  'Explore Merchants': 'Explore Merchants',
  'Failed to save image': 'Failed to save image',
  'Female' : 'Female',
  'First Name': 'First Name',
  'For Sale': 'For Sale',
  'Forgot password': 'Forgot password',
  'Free Apply': 'Free Apply',
  'Free Claim': 'Free Claim',
  'Full Size': 'Full Size',
  'Gender': 'Gender',
  'General': 'General',
  'Get Verification code': 'Get Verification code',
  'Gift Card': 'Gift Card',
  'Have an account already': 'Have an account already',
  'Head Image': 'Head Image',
  'Home': 'Home',
  'Image saved successfully': 'Image saved successfully',
  'Import': 'Import',
  'Import success': 'Import success',
  'In Use': 'In Use',
  'Incorrect Code!': 'Incorrect Code!',
  'Incorrect Username/Password': 'Incorrect Username/Password',
  'Incorrect! Please try again.': 'Incorrect! Please try again.',
  'Input your answer': 'Input your answer',
  'Input VIP\'s phone number': 'Input VIP\'s phone number',
  'Insufficient Points': 'Insufficient Points',
  'Invalid QR Code': 'Invalid QR Code',
  'Item Code': 'Item Code',
  'Voucher': 'Voucher',
  'Language': 'Language',
  'Last Name': 'Last Name',
  'Layout': 'Layout',
  'Less': 'Less',
  'Link Copied': 'Link Copied',
  'Link VIP:': 'Link VIP:',
  'Link VIP Card': 'Link VIP Card',
  'Link Your VIP Card': 'Link Your VIP Card',
  'Link_Your_VIP_Card_DESC1': 'This phone number ',
  'Link_Your_VIP_Card_DESC2': ' is already registered. Select a security question and input the answer below for verification.',
  'Link_Your_VIP_Card_With_Phone_DESC': 'To link a phone number with VIP card. Select a security question and input the answer below for verification.',
  'Load': 'Load',
  'Loading...': 'Loading...',
  'Location': 'Location',
  'Login': 'Login',
  'Loginalertpart1': 'Unable to display, please',
  'Loginalertpart2': ' login ',
  'Loginalertpart3': 'immediately',
  'Logout': 'Logout',
  'Logo and Name': 'Logo and Name',
  'Main Merchant': 'Main Merchant',
  'Male' : 'Male',
  'Max Quantity': 'Max Quantity',
  'Member Coupons': 'Member Coupons',
  'Member Information': 'Member Information',
  'Merchant': 'Merchant',
  'Merchant Group': 'Merchant Group',
  'Mine': 'Mine',
  'Min Spend': 'Min Spend',
  'More...': 'More...',
  'More Promotions': 'More Promotions',
  'My VIP Cards': 'My VIP Cards',
  'My Wallet': 'My Wallet',
  'New user': 'New user',
  'Name': 'Name',
  'No': 'No',
  'No Content': 'No Content',
  'No Coupon': 'No Coupon',
  'No Data': 'No Data',
  'No data created': 'No data created',
  'No data in excel file': 'No data in excel file',
  'No Import Data': 'No Import Data',
  'No Voucher': 'No Voucher',
  'No Logo, No Name': 'No Logo, No Name',
  'No Vip Card': 'No Vip Card',
  'Notes': 'Notes',
  'Note: The quantity is limited, please check with the staff before using.': 'Note: The quantity is limited, please check with the staff before using.',
  'Offline': 'Offline',
  'Once closing done, all data below will be wiped out:': 'Once closing done, all data below will be wiped out:',
  'Online': 'Online',
  'Online reload is not currently supported': 'Online reload is not currently supported',
  'Only Logo': 'Only Logo',
  'Only Name': 'Only Name',
  'Orange Wallet App': 'Orange Wallet App',
  'OK': 'OK',
  'OK2': 'OK',
  'Others': 'Others',
  'Password': 'Password',
  'Password invalid': 'Password invalid',
  'Pay Amount': 'Pay Amount',
  'Pay Cancel': 'Pay Cancel',
  'Personal Info': 'Personal Info',
  'Personal Information': 'Personal Information',
  'Personal information': 'Personal information',
  'Phone': 'Phone',
  'Phone number': 'Phone number',
  'Phone Number': 'Phone Number',
  'Phone number cannot be empty': 'Phone number cannot be empty',
  'Phone number invalid':'Phone number invalid',
  'Place Order': 'Place Order',
  'Please go to the merchant counter for reload': 'Please go to the merchant counter for reload',
  'Please Input Verify Code': 'Please Input Verify Code',
  'Please provide an answer': 'Please provide an answer',
  'Please provide a name': 'Please provide a name',
  'Please provide a valid email': 'Please provide a valid email',
  'Please read and agree to the terms and conditions': 'Please read and agree to the terms and conditions',
  'Please scan merchant QR Code': 'Please scan merchant QR Code',
  'Points': 'Points',
  'Points History': 'Points History',
  'Points Earned': 'Points Earned',
  'Points Used': 'Points Used',
  'Point Mall': 'Point Mall',
  'Points Mall': 'Points Mall',
  'Point Rate(Total to Point Rate)': 'Point Rate(Total to Point Rate)',
  'Point Rules': 'Point Rules',
  'Preset Reload and Bonus': 'Preset Reload and Bonus',
  'Preset part1': 'Reload',
  'Preset part2': 'Get',
  'Preset part3': 'Bonus',
  'Rebate': 'Rebate',
  'Redeem': 'Redeem',
  'Redeem2': 'Redeem',
  'Redeem ': 'Redeem ',
  'Redeemable': 'Redeemable',
  'Redeemed': 'Redeemed',
  'Redeemconfirmpart1': 'Sure to redeem ',
  'Redeemconfirmpart2': '',
  'Redeemconfirmpart3': ' points will be deducted',
  'Redeem Prompt': 'Redeem Prompt',
  'Register': 'Register',
  'Reload': 'Reload',
  'Reload2': 'Reload',
  'Reload Amount': 'Reload Amount',
  'Reload Cancel': 'Reload Cancel',
  'Resendpart1': 'You can resend in',
  'Resendpart2': 'seconds',
  'Resendpart3': 'No code received?',
  'Resendpart4': 'Resend',
  'Resend': 'Resend',
  'Reset Password': 'Reset Password',
  'Rules': 'Rules',
  'Save': 'Save',
  'Scan': 'Scan',
  'Scan your card here': 'Scan your card here',
  'Search': 'Search',
  'Search Store': 'Search Store',
  'seconds': 'seconds',
  'Security Answer': 'Security Answer',
  'Security Question': 'Security Question',
  'See All': 'See All',
  'Select': 'Select',
  'Select Date': 'Select Date',
  'Select file(xlsx) to import': 'Select file(xlsx) to import',
  'Select Gender': 'Select Gender',
  'Select Language': 'Select Language',
  'Setup Password': 'Setup Password',
  'Share': 'Share',
  'Sign In': 'Sign In',
  'Sold': 'Sold',
  'Something Worng, Please try it later.': 'Something Worng, Please try it later.',
  'Sorry, please keep earning points to unlock products!': 'Sorry, please keep earning points to unlock products!',
  'Seconds': 'Seconds',
  'Status': 'Status',
  'Store': 'Store',
  'Store Detail': 'Store Detail',
  'Submit': 'Submit',
  'Submit Request': 'Submit Request',
  'System': 'System',
  'Tag': 'Tag',
  'Take Out': 'Take Out',
  'to': 'to',
  'terms and conditions': 'terms and conditions',
  'The product has been successfully redeemed.': 'The product has been successfully redeemed.',
  'Title_off': 'OFF',
  'Title_rebate': 'REBATE',
  'Transaction Records': 'Transaction Records',
  'Transactions, reloads and points history': 'Transactions, reloads and points history',
  'Try Again': 'Try Again',
  'Update status successfully': 'Update status successfully',
  'Update vip card successfully': 'Update vip card successfully',
  'Used': 'Used',
  'Used / Expired': 'Used / Expired',
  'Username invalid': 'Username invalid',
  'Use Now': 'Use Now',
  'Valid': 'Valid',
  'Valid In': 'Valid In',
  'Valid Stores': 'Valid Stores',
  'Valid Until': 'Valid Until',
  'Verification Code': 'Verification Code',
  'Verification Code is sended to': 'Verification Code is sent to',
  'Verification Failed:': 'Verification Failed:',
  'Verification Success!': 'Verification Success!',
  'Verify': 'Verify',
  'Verify Error': 'Verify Error',
  'Version': 'Version',
  'View Balance': 'View Balance',
  'VIP canceled': 'VIP canceled',
  'VIP canceled, contact merchant to restore': 'VIP canceled, contact merchant to restore',
  'VIP Card': 'VIP Card',
  'VIP Level': 'VIP Level',
  'Welcome': 'Welcome',
  'Wallet': 'Wallet',
  "What's the Last name of this account?": "What's the Last name of this account?",
  "What's the first name of this account?": "What's the first name of this account?",
  "What's the VIP number of this account?": "What's the VIP number of this account?",
  'Worth': 'Worth',
  'Yes': 'Yes',
  'Your phone number could not be verified. If you have any question, please contact the merchant.': 'Your phone number could not be verified. If you have any question, please contact the merchant.',
  'Your phone number was successfully linked to virtual VIP card.': 'Your phone number was successfully linked to virtual VIP card.',
  'Your virtual VIP card is now linked with physical card.': 'Your virtual VIP card is now linked with physical card.',
  ' related account': ' related account',
  // more translation keys...
}
