import redirectToHome from './redirectToHome';
import useToast from './useToast';
import api from '../api';
import { Preferences as Storage } from '@capacitor/preferences';
import UserTypes from '../enums/UserTypes';
import couponFunc from './coupon';
import vipcardFunc from './vipcard';

import md5 from 'js-md5'

export default function () {
  const { openToast } = useToast();
  const { redirectTo } = redirectToHome();
  const { applyCoupon } = couponFunc();
  const { applyvipcard } = vipcardFunc();

  async function userLogin(userCredentials) {
    const cur_merchant = await Storage.get({ key: 'cur_merchant_id' });
    let cur_merchant_id = cur_merchant.value?cur_merchant.value:0;
    const data = {
      username: userCredentials.username,
      password: userCredentials.password,
      lastMerchant_id: userCredentials.lastMerchant_id,
      lang: userCredentials.lang,
    };

    return this.$store.dispatch('login/login', data)
      .then((response) => {
        if (response.status == 0) {
          openToast('Logged with sucess', 'success', 'top');
        } else {
          // openToast(response.message, 'danger', 'top');
        }

        // redirectTo(
        //   response.data.user.memberType,
        //   { anyCustomParams: respones.data.user },
        //   true,
        // );

        return Promise.resolve(response);
      })
      .catch(() => {
        openToast('Something was wrong on login', 'danger', 'top');
        Promise.reject("Login got Exception");
      });
  }

  // Setup phone number in system first time
  async function registerPhone(phone, forceSms=0) {
    var form = new FormData();
    form.append("phone", phone);
    form.append("forceSms", forceSms);
    try {
      const response = await api.post('/phone', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Unknown Phone Number, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      openToast('Unknown phone number, please try later', 'warning', 'top');
      return "";
    }
  }

  // Setup phone number in system first time
  async function verifyPhone(phone, code) {
    var form = new FormData();
    form.append("phone", phone);
    form.append("code", code);
    try {
      const response = await api.post('/verifyphone', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Unknown Phone Number, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      openToast('Unknown phone number, please try later', 'warning', 'top');
      return "";
    }
  }

  async function userRegister(userFrom) {
    const cur_merchant = await Storage.get({ key: 'cur_merchant_id' });
    let cur_merchant_id = cur_merchant.value?cur_merchant.value:0;
    const member = await Storage.get({ key: 'memberType' });
    let memberType = member.value?member.value:UserTypes.MEMBER;
    var form = new FormData();
    if ((userFrom.username == undefined) || !userFrom.username) {
      openToast('Unknown User Phone number', 'warning', 'top');
      return "";
    }
    if ((userFrom.password == undefined) || !userFrom.password) {
      openToast('Unknown User Password', 'warning', 'top');
      return "";
    }
    form.append("username", userFrom.username);
    form.append("password", userFrom.password);
    form.append("memberType", memberType);
    form.append("merchant_id", cur_merchant_id);
    form.append("lastMerchant_id", cur_merchant_id);
    if (userFrom.name != undefined) {
      form.append("name", userFrom.name);
    }
    if (userFrom.address != undefined) {
      form.append("address", userFrom.address);
    }
    if (userFrom.email != undefined) {
      form.append("email", userFrom.email);
    }
    form.append("phone", userFrom.username);
    if (userFrom.notes != undefined) {
      form.append("notes", userFrom.notes);
    }
    form.append("lang", userFrom.lang);
    try {
      const response = await api.post('/register', form);
      if (response.status == 200) {
        openToast('Logged with sucess', 'success', 'top');
        const data = response.data;
        if (data.status == 0) {
          await Storage.set({
            key: 'token',
            value: data.data.token,
          });
          let user = data.data.user;
          user.userId = user.id;
          await Storage.set({
            key: 'user',
            value: JSON.stringify(user),
          });

          await autoApply();
        }
        return data;
      }
      openToast('User Register Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function autoRegister(userFrom) { // Register phone only
    var form = new FormData();
    if ((userFrom.phone == undefined) || !userFrom.phone) {
      openToast('Unknown User Phone number', 'warning', 'top');
      return "";
    }
    form.append("username", userFrom.phone);
    // form.append("password", userFrom.password);
    form.append("merchant_id", userFrom.merchant_id);
    // form.append("lastMerchant_id", userFrom.merchant_id);
    form.append("phone", userFrom.phone);
    form.append("lang", userFrom.lang);
    if (userFrom.coupon_id != undefined) {
      form.append("coupon_id", userFrom.coupon_id);
    }
    if (userFrom.lead_id != undefined) {
      form.append("lead_id", userFrom.lead_id);
    }
    if (userFrom.notes != undefined) {
      form.append("notes", userFrom.notes);
    }
    try {
      const response = await api.post('/autoRegister', form);
      if (response.status == 200) {
        openToast('Logged with sucess', 'success', 'top');
        const data = response.data;
        if (data.status == 0) {
          await Storage.set({
            key: 'token',
            value: data.data.token,
          });
          let user = data.data.user;
          user.userId = user.id;
          await Storage.set({
            key: 'user',
            value: JSON.stringify(user),
          });
        }
        return data;
      }
      openToast('User Register Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function autoApply() {
    const auto_apply_card = await Storage.get({ key: 'auto_apply_card' });
    if (auto_apply_card.value) {
      await applyvipcard(auto_apply_card.value).then((rt)=>{
        console.log("Auto Apply Card", rt);
      }).catch((err)=>{
        console.log("Auto Apply Card Error", err);
      });

      Storage.set({
        key: 'auto_apply_card',
        value: '',
      });
    }

    const auto_apply_coupon = await Storage.get({ key: 'auto_apply_coupon' });
    if (auto_apply_coupon.value) {
      const leadval = await Storage.get({ key: 'cur_lead_id' });
      var lead_id = parseInt(leadval.value);
      await applyCoupon(auto_apply_coupon.value, lead_id).then((rt)=>{
        Storage.remove({ key: 'cur_lead_id' });
        console.log("Auto Apply Coupon", rt);
      }).catch((err)=>{
        console.log("Auto Apply Coupon Error", err);
      });
        
      Storage.set({
        key: 'auto_apply_coupon',
        value: '',
      });
    }
  }

  async function userCheckUsername(username) {
    var form = new FormData();
    form.append("token", md5(username + 'posW230810'));
    form.append("username", username);
    try {
      const response = await api.post('/check', form);
      if (response.status == 200) {
        const data = response.data;
        if ((data.status == 0) && (data.data.message == "")) {
          return "";
        }
      }
      openToast('Username Existed, please try other one', 'warning', 'top');
      return 'Username Existed, please try other one';
    } catch (error) {
      throw error;
    };
  }

  async function sendResetPassword(phone) {
    var form = new FormData();
    form.append("phone", phone);
    try {
      const response = await api.post('/resetpwd', form);
      if (response.status == 200) {
        openToast('Please check your phone', 'success', 'top');
        const data = response.data;
        return data;
      }
      openToast('Refuse to reset password', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function updatePassword(password) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("id", user.userId);
    form.append("password", password);
    try {
      const response = await api.post('/setpwd', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Set Password Fail', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function getPersonalInfo() {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    try {
      const response = await api.post('/getPersonalInfo', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get PersonalInfo Fail', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function saveUser(user) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("id", curuser.userId);
    if ((user.email != undefined) && user.email) form.append("email", user.email);
    if ((user.name != undefined) && user.name) form.append("name", user.name);
    if ((user.dob != undefined) && user.dob) form.append("dob", user.dob);
    if ((user.gender != undefined) && user.gender) form.append("gender", user.gender);
    if (user.address != undefined) form.append("address", user.address);
    if (user.langPreferred != undefined) form.append("langPreferred", user.langPreferred);

    try {
      const response = await api.post('/admin/updateMember', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Set Password Fail', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function closeAccount() {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      // openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    try {
      const response = await api.post('/closeAccount', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Closing account Failed', 'warning', 'top');
      return "1";
    } catch (error) {
      throw error;
    }
  }
  
  async function setLang(lang) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("lang", lang);

    try {
      api.post('/setLang', form);
    } catch (error) {
    }
  }

  return {
    userLogin,
    registerPhone,
    verifyPhone,
    userRegister,
    autoRegister,
    userCheckUsername,
    openToast,
    redirectTo,
    sendResetPassword,
    updatePassword,
    getPersonalInfo,
    saveUser,
    autoApply,
    closeAccount,
    setLang,
  };
}
